<template>
  <div v-if="!udata">Loading Please wait...
  </div>
  <div v-else>
    <!-- User Info: Input Fields -->
    <validation-observer #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('benutzer')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="udata.BenutzerID"
                :disabled="$g.user.Passwort !== 'password'"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Vorname"
              rules="required"
            >
              <b-form-group
                label="Vorname"
                label-for="Vorname"
              >
                <b-form-input
                  id="Vorname"
                  v-model="udata.Vorname"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Prénom...' : 'Vorname...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="udata.Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Nom...' : 'Name...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="udata.EMail"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Entrer valide courielle' : 'Gültige E-Mail eingeben' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Rolle"
              label-for="user-role"
            >
              <v-select
                v-model="udata.Rolle"
                :disabled="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Sprache"
              label-for="sprache"
            >
              <v-select
                v-model="udata.Sprache"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sprachen"
                :reduce="val => val.value"
                :clearable="false"
                input-id="sprache"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12">
            <app-collapse>
              <app-collapse-item title="Zweifaktor Authentisierung (2FA)">

                <b-row>
                  <b-col cols="12" md="12">
                    <blockquote>
                      Zur Aktivierung der Zweifaktorauthentisierung muss die Google oder Microsoft Authenticator App auf dem Smartpohne installiert sein.<br>
                      Vorgehen zur Aktivierung des Benutzers für separis:<br>
                      1. QR-Code generieren<br>
                      2. Authenticator App öffnen und QR-Code scannen<br>
                      3. Code eingeben und Code verifizieren.<br>
                      Vorgehen nach Deaktivierung: Code aus Authenticator App eingeben und Code verifizieren.
                      Vorgehen bei Smartphone-Wechsel: ZFA deaktivieren und wie oben beschrieben aktivieren.<br>
                      Vorgehen wenn Authenticator App nicht mehr verfügbar: Passwort vergessen wählen, anmelden mit temporärem Passwort aus E-Mail, wie oben beschrieben aktivieren.
                  </blockquote>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col cols="12" md="1" v-show="udata.Ist2FA">
                    <b-form-group
                      label="Aktivieren"
                      label-for="VE-FE"
                    >
                      <b-form-checkbox
                        v-model="udata.Ist2FA"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3" class="mt-1 text-right">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="getQRCode"
                    >
                      QR-Code generieren
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="2" class="text-left">
                    <div class="text-center mb-1">
                      <b-img
                        max-witdh="100%"
                        max-height="100"
                        :src="qRCode"
                      />
                    </div>
                  </b-col>

                  <b-col cols="12" md="2">
                    <b-form-group
                      label="Code"
                      label-for="username"
                    >
                      <b-form-input v-mask="'### ###'"
                        v-model="code"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="2" class="mt-1">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="verifyQRCode"
                    >
                      Code verifizieren
                    </b-button>
                  </b-col>
                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col cols="12" md="12" class="mt-1">
            <app-collapse>
              <app-collapse-item title="Passwort ändern">
                <!-- password -->
                <b-form-group
                  label="Aktuelles Passwort"
                  label-for="akt-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="curPassword"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-show="errors.length"
                      class="text-danger"
                    >{{ $g.browserLocale === 'fr' ? 'Il faut spécifier le mot de passe actuel' : 'Das aktuelle Passwort muss angegeben werden' }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Neues Passwort"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-show="errors.length"
                      class="text-danger"
                    >{{ $g.browserLocale === 'fr' ? 'Longueur au moins 8 et au moins une lettre majuscule et minuscule et un caractère spécial' : 'Länge mindestens 8 und mindestens einem Klein- und Grossbuchstaben und Sonderzeichen' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Passwort Bestätigung"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="reset-password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="password.length === 0 || invalid || (password !== cPassword)"
                  @click="changePwd"
                >
                  Passwort ändern
                </b-button>
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <!-- <b-col cols="12" md="12" class="mt-1">
            <app-collapse>
              <app-collapse-item title="Justitia 4.0 - IDP">

                <b-row>
                  <b-col cols="12" md="2">
                    <b-form-group
                      label="Benutzername"
                      label-for="username"
                    >
                      <b-form-input
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col> -->

          <!-- <b-col cols="12" md="12" class="mt-1">
            <app-collapse>
              <app-collapse-item title="ChatGPT">

                <b-row>
                  <b-col cols="12" md="2">
                    <b-form-group
                      label="API-Key"
                      label-for="cgapikey"
                    >
                      <b-form-input
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col> -->

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              class="mt-1 mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="invalid"
              @click="save_changes"
            >
              Speichern
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
          />
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-show="$g.user.Passwort === 'password'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="send_pwd"
            >
              Benutzer/Passwort senden
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupAppend, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ben from '@/service/benutzer'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Configuration, OpenAIApi } from 'openai'

// import { Row } from '@syncfusion/ej2-charts'
// import { Validator } from 'vee-validate'

Vue.use(VueMask)

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BImg,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    // Row,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      udata: null,
      curPassword: '',
      cPassword: '',
      password: '',
      required,
      email,
      password1FieldType: 'password',
      password2FieldType: 'password',
      qRCode: '',
      code: '',
      config: null,
      openai: null,
      question: '',
      answer: '',
      isCalc: false,
      answers: [],
      context: '',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    const r = await ben.getBenutzer(this.$g.user.BenutzerID)
    if (r !== null && r.data.length === 1) this.udata = r.data[0]

    this.config = new Configuration({
      apiKey: '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',
    })
    this.openai = new OpenAIApi(this.config)
  },
  setup() {
    const sprachen = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Français', value: 'fr' },
    ]

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Benutzer', value: 'berater' },
    ]

    const statusOptions = [
      { label: 'Aktiv', value: true },
      { label: 'Inaktiv', value: false },
    ]

    return {
      sprachen,
      roleOptions,
      statusOptions,
    }
  },
  methods: {
    isFormDirty() {
      return Object.keys(this.fields).some(key => this.fields[key].dirty)
    },
    async save_changes() {
      await ben.putBenutzer(this.udata)
      this.$i18n.locale = this.udata.Sprache
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Änderungen gespeichert',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Änderungen wurden erfolgreich gespeichert!!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async send_pwd() {
      await ben.sendMandantPwd(this.udata.BenID, this.udata.BenutzerID, this.udata.Sprache, this.udata.EMail, this.udata.Passwort)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Passwortversand',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Passwort wurde erfolgreich dem Mandanten versandt!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async changePwd() {
      await ben.changePwd(this.udata.BenID, this.curPassword, this.password)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Passwortänderung',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Passwort wurde erfolgreich geändert!',
          },
        },
        { position: 'bottom-right' },
      )
    },
    async getQRCode() {
      const r = await ben.getTFAQRCode(this.udata.BenutzerID)
      if (true) {
        this.qRCode = r.data
      }
    },
    async verifyQRCode() {
      const r = await ben.getTFAVerify(this.udata.BenutzerID, this.code.replace(' ', ''))
      if (r.data === true) {
        if (await this.$root.msgBoxConfirm('ZFA bereit - jetzt aktivieren ab dem nächsten Login?')) {
          this.udata.Ist2FA = true
          await ben.putBenutzer(this.udata)
          this.$root.showToast('ZFA aktiviert', 'ZFA aktiviert ab der nächsten Anmeldung!')
        }
      }

      if (r.data === false) {
        if (await this.$root.msgBoxConfirm('Der Code war falsch - bitte nochmals versuchen')) {
          this.$root.showToast('Code falsch', 'Code falsch oder E-Mail noch nicht für separis registiert!')
        } else {
          this.udata.Ist2FA = false
          await ben.putBenutzer(this.udata)
          this.$root.showToast('Code falsch', 'Code falsch oder E-Mail noch nicht für separis registiert!')
        }
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
